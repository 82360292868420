import logo from './logo.svg';
import './App.css';
import img1 from "../src/Data/images/img1.gif"

function App() {
  return (
    <div   style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100vw", height:"100vh", backgroundColor:"black", overflow:"hidden"}}>
      <img alt='loading' src={img1} style={{objectFit:"contain"}}/>
      <h1 style={{color:"white",}}>Coming Soon</h1>
    </div>
  );
}

export default App;
